import React from 'react'
import PropTypes from 'prop-types'

import { UILayout, UIText } from 'bora-material-ui'

import UIElements from '../consts/UIElements'

const styles = {
  margin0WidthAuto: { margin: '0', width: 'auto' },
  marginBottom24: { margin: '0 0 24px 0' },
  fieldLabel: { width: '100px', whiteSpace: 'nowrap', size: '18px', align: 'left', padding: '0' },
}

const xsStyles = {
  heightAuto: { height: 'auto' },
}

const Content = ({ className, id, header, children }, { muiTheme }) => (
  <UILayout
    className={className}
    column
    center
    padding="0"
    margin={`${UIElements.HEADER ? '-97px' : '40px'} 0 0`}
    xs={styles.margin0WidthAuto}
    {...muiTheme.ids[id]}
  >
    <UILayout center j-flex-center height="72px" id="authTitle" dispay-if={header}>
      <UIText
        size="24px"
        textTransform="uppercase"
        xs={xsStyles.heightAuto}
        text={header}
        font={muiTheme.secondFontFamilyDem}
      />
    </UILayout>
    {children}
  </UILayout>
)

Content.defaultProps = {
  id: '',
  header: '',
}

Content.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  header: PropTypes.string,
  children: PropTypes.any.isRequired,
}

Content.contextTypes = {
  muiTheme: PropTypes.object,
}

export default Content
